import { get, post } from "../assets/functions";
import store from "../store/index";
import router from "../router";
export default {
  namespaced: true,
  state: {
    email: "",
    firstName: "",
    lastName: "",
    role: "",
    hash: '',
    avatar: '',
    company: {},
    billingInfo: {},
    countries: [],
    routerHistory: [],
    cacheVue: 0,
    downloads: {}
  },
  mutations: {
    setUser(state, response) {
      state.email = response.email;
      state.firstName = response.firstName;
      state.fullName = response.fullName;
      state.lastName = response.lastName;
      state.role = response.role;
      state.hash = response.hash;
      state.avatar = response.avatar;
    },
    setCompany(state, response) {
      state.company = response.company;
    },
    countries(state, response) {
      state.countries = response.countries;
    },
    setBilling(state, response) {
      state.billingInfo = response.billingInfo;
    },
    cacheVue(state, response) {
      state.cacheVue = response;
    },

    clear(state) {
      state.email = "";
      state.firstName = "";
      state.lastName = "";
    },
    downloads(state, val) {
      state.downloads = val;
    },
    routerHistory(state, response) {
      const history = state.routerHistory;
      if ( history ) {
        if (history.length > 10)
          state.routerHistory.shift();
      }
      state.routerHistory.push(response);
    },
  },
  actions: {
    async logout(context) {
      await get("/logout");
      return context.commit("clear");
    },
    async getUserInformation(context, data) {
      const response = await get("/api/me");
      context.commit("setUser", response);
      context.commit("setBilling", response);
      return response;
    },
    async postUserInformation(context, data) {
      const response = await post("/api/me", data);
      store.commit("notifications/raw", response);
      return response;
    },
    async getDownloads(context) {
      let response = await get("/api/configuration/downloads");
      context.commit("downloads", response);
    },
    async postCompany(context, data) {
      const response = await post("/api/me/billing", data);
      store.commit("notifications/raw", response);
      return response;
    },
    async postPartnerInformation(context, data) {
      const response = await post("/api/reseller/me", data);
      store.commit("notifications/raw", response);
      return response;
    },
    async getResellerInformation(context, data) {
      const response = await get("/api/reseller/me");
      context.commit("setUser", response);
      context.commit("setCompany", response);
      return response;
    },
    async getCountries(context, data) {
      const response = await get("/api/countries");
      context.commit("countries", response);
      return response;
    },
    async forgotPassword(context, data) {
      const response =  await post("/api/password", { email: data });
      store.commit("notifications/raw", response);
      return response
    },
    async resetPassword(context, data) {
      const response =  await post("/api/password/reset", data);
      store.commit("notifications/raw", response);
      return response
    },
    async forgotResellerPassword(context, data) {
      const response =  await post("/api/reseller/password", { email: data });
      store.commit("notifications/raw", response);
      return response
    },
    async resetResellerPassword(context, data) {
      const response =  await post("/api/reseller/password/reset", data);
      store.commit("notifications/raw", response);
      return response
    },
    async setProfilePicture(context, data) {
      const url = process.env.VUE_APP_URL + '/api/avatar';
      const response = await fetch(url, {
        method: "POST",
        body: data,
        credentials: "include",
      });
      return await response.json();
    },
    async setResellerProfilePicture(context, data) {
      const url = process.env.VUE_APP_URL + '/api/reseller/avatar';
      const response = await fetch(url, {
        method: "POST",
        body: data,
        credentials: "include",
      });
      return await response.json();
    },
  },
};
