export default {
  namespaced: true,
  state: {
    deleteAccountConfirmation: false,
    deleteSupportConfirmation: false,
    deleteLicenseConfirmation: false,
    supportTicket: false,
    terms: false,
    appInfo: false,
  },
  mutations: {
    deleteAccountConfirmation(state, value) {
      state.deleteAccountConfirmation = value;
    },
    deleteSupportConfirmation(state, value) {
      state.deleteSupportConfirmation = value;
    },
    deleteLicenseConfirmation(state, value) {
      state.deleteLicenseConfirmation = value;
    },
    supportTicket(state, value) {
      state.supportTicket = value;
    },
    appInfo(state, value) {
      state.appInfo = value;
    },
    terms(state, value) {
      state.terms = value;
    },
  },
};
