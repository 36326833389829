<template>
  <div id="app">
    <router-view  />
    <ModalController />
  </div>
</template>

<script>
import { mapState } from "vuex";
import ModalController from "@/components/Modals/Assets/Controller.vue";
export default {
  components: {
    ModalController,
  },
  methods: {
  
  },
  computed: {
    
  },
 async  mounted() {

  },
};
</script>

