import { del, get, post } from "../assets/functions";
import store from "./index";
export default {
  namespaced: true,
  actions: {
    async login(context, data) {
      return await post("/login", data);
    },
    async adminlogin(context, data) {
      return await post("/api/administration/login", data);
    },
    async logout(context) {
      await get("/reserller/logout");
      return context.commit("clear");
    },
    async partnersLogout(context) {
      await get("/reseller/logout");
      return context.commit("clear");
    },
    async register(context, data) {
      return await post("/register", data);
    },
    async reSellerRegister(context, data) {
      return await post("/reseller/register", data);
    },
    async reSellerLogin(context, data) {
      return await post("/login", data);
    },
    async getResellerLogo(context, data) {
      return await get(`/api/reseller/logo/url/${data}`);
    },
    async postResellerLogo(context, data) {
      const url = process.env.VUE_APP_URL + '/api/reseller/logo';
      const response = await fetch(url, {
        method: "POST",
        body: data,
        credentials: "include",
      });
      return await response.json();
    },
  },
};
