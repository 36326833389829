import Vue from "vue";
import Vuex from "vuex";
import admin from "./admin"
import auth from "./auth"
import user from "./user";
import licenses from "./licenses";
import accounts from "./accounts";
import modals from "./modals";
import invoice from "./invoice";
import products from "./products";
import toolbars from "./toolbars";
import payment from "./payment";
import customers from "./customers";
import notifications from "./notifications";
import supportTickets from './supportTickets'
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
    licenses,
    accounts,
    modals,
    toolbars,
    payment,
    products,
    customers,
    notifications,
    invoice,
    auth,
    admin,
    supportTickets
  },
  plugins: [
    createPersistedState({
      paths: [
        "user",
        "licenses",
        "modals",
        "accounts",
        "products",
        "customers",
        "toolbars",
        "payment",
        "notifications",
        "invoice",
        "auth",
        "admin",
        "supportTickets"
      ],
    }),
  ],
});
