import { del, get, post } from "../assets/functions";

const getDefaultState = () => {
  return {
    products: [],
    active: null,
    
  };
};

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    resetState(state) {
      Object.assign(state, getDefaultState());
    },
    products(state, val) {
      state.products = val;
    },
    active(state, val) {
      state.active = val;
    },

  },
  actions: {
    async getProducts(context) {
      const response = await get("/api/products");
      context.commit("products", response);
      return response;
    },
    async getResellerProducts(context) {
      const response = await get("/api/reseller/products");
      context.commit("products", response);
      return response;
    },
  },
};
