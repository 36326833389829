import { del, get, post } from "../assets/functions";
import store from "./index";

export default {
    namespaced: true,
    state: {
        partners: [],
        partner: {},
        users: [],
        user: {},
        customers: [],
        customer: {}
    },
    mutations: {
        partners(state, response) {
            state.partners = response;
        },
        partner(state, response) {
            state.partner = response;
        },
        customers(state, response) {
            state.customers = response;
        },
        customer(state, response) {
            state.customer = response;
        },
        users(state, response) {
            state.users = response;
        },
        user(state, response) {
            state.user = response;
        },
    },
    actions: {
        async getAdminMe(context, data) {
            const response = await get("/api/administration/me");
            store.commit("user/setUser", response);
            return response;
        },
        async getPartners(context, id) {
            const response = await get(`/api/administration/partners/${id || ''}`);
            if (id) context.commit("partner", response);
            else context.commit("partners", response);
            return response;
        },
        async getPartnersUsers(context, data) {
            let url = `/api/administration/partners/users/${data.partner || ''}/${data.user || ''}`
            const response = await get(url);
            if (data.user) context.commit("user", response);
            else context.commit("users", response);
            return response;
        },
        async getCustomers(context, id) {
            const response = await get(`/api/administration/customers/${id || ''}`);
            if (id) context.commit("customer", response);
            else context.commit("customers", response);
            return response;
        },
        async getPartnersCustomers(context, data) {
            let url = `/api/administration/customers/partner/${data.partner || ''}/${data.user || ''}`
            const response = await get(url);
            if (data.user) context.commit("customer", response);
            else context.commit("customers", response);
            return response;
        },
    },
};

