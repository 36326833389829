<template>
  <Modal>
    <form @submit.prevent="post" class="col gap-50">
      <section class="header row space-between align-center">
        <span class="font-18">Open New Ticket</span>
        <button @click="close" class="center">
          <s-icon color="grey">close</s-icon>
        </button>
      </section>
      <section class="body">
        <div class="grid align-center mt-20">
          <div class="font-14 opacity-60">Title</div>
          <s-text-field autofocus v-model="title"></s-text-field>
          <div class="font-14 opacity-60">Category</div>
          <s-dropdown
            customPlaceholder="Please select category"
            :height="40"
            class="w100"
            noSelect="true"
            v-model="category"
            :items="items"
          ></s-dropdown>
        </div>
        <div class="col pt-40 gap-20">
          <div class="font-14 opacity-60">Description of your issue</div>
          <s-text-area height="150" v-model="description"></s-text-area>
        </div>
      </section>
      <section class="footer row gap-20 justify-end">
        <s-btn type="button" height="36" @click.native="close">
          {{ $t("modal.cancel") }}
        </s-btn>
        <s-btn height="36" class="green"> create </s-btn>
      </section>
    </form>
  </Modal>
</template>
  
<script>
import { mapState } from "vuex";
import dayjs from "dayjs";
export default {
  data() {
    return {
      title: "",
      description: "",
      category: "",
    };
  },
  methods: {
    close() {
      this.$store.commit("modals/supportTicket", false);
    },
    format(val) {
      return dayjs(val).format("DD MMM YY");
    },
    async post() {
      if (!this.category) return this.notification()
      let data = {
        title: this.title,
        category: this.category.value,
        content: this.description,
      };
      await this.$store.dispatch("supportTickets/postSupportTicket", data);
      this.close();
    },
    notification(){
      this.$store.commit("notifications/push", {
        error: `Please select a category`,
      });
    }
  },
  computed: {
    ...mapState("supportTickets", ["categories"]),
    items() {
      return this.categories.map((el) => {
        let capital = el.charAt(0).toUpperCase() + el.slice(1);
        return { display: capital, value: el, selected: false };
      });
    },
  },
};
</script>
  
<style scoped>
.grid {
  display: grid;
  grid-template-columns: 150px auto;
  grid-row-gap: 15px;
}

.textArea {
  min-height: 100px;
}
</style>