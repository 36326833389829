<template>
  <div
    class="bulgy-radios d-flex align-center wrap"
    :class="{ vertical: vertical == '' }"
    role="radiogroup"
    aria-labelled-by="bulgy-radios-label"
  >
    <label
      v-for="(item) in options"
      :key="item"
      @click="$emit('input', item)"
    >
      <button type="button">
        <input type="radio" name="options" :checked="value == item" />
        <span class="radio"></span>
        <span class="label">{{ item.charAt(0).toUpperCase() + item.slice(1) }}</span>
      </button>
    </label>
  </div>
</template>

<script>
export default {
  props: ["value", "vertical", "options"],
  mounted() {},
};
</script>

<style scoped lang='less'>
.vertical {
  flex-direction: column;
  align-items: flex-start;
}

.bulgy-radios label {
  display: block;
  position: relative;
  padding-left: 1.5rem;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: rgb(170, 170, 170);
  letter-spacing: 1px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.bulgy-radios label:hover input:not(:checked) ~ .radio {
  opacity: 0.8;
}
.bulgy-radios .label {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding-right: 1rem;
}
.bulgy-radios .label span {
  line-height: 1em;
}
.bulgy-radios input {
  position: absolute;
  cursor: pointer;
  height: 0;
  width: 0;
  left: -2000px;
  display: none;
}
.bulgy-radios input:checked ~ .radio {
  -webkit-transition: background 0.3s;
  transition: background 0.3s;
}
.bulgy-radios input:checked ~ .radio::after {
  opacity: 1;
}
.bulgy-radios input:checked ~ .label {
  color: #ffffff;
}

.radio {
  position: absolute;
  left: 0;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  border: 1px solid white;
}
.radio::after {
  content: "";
  position: absolute;
  opacity: 0;
  top: 2px;
  left: 2px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #0ac07d;
}

@media only screen and (max-width: 576px) {
  .bulgy-radios {
    flex-direction: column;
    align-items: flex-start;
  }
}
</style>