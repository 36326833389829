import { del, get, post, put } from "../assets/functions";
import store from "./index";

const getDefaultState = () => {
    return {
        supportTickets: [],
        categories: [],
        supportTicket: {}
    };
};

export default {
    namespaced: true,
    state: getDefaultState(),
    mutations: {
        resetState(state) {
            Object.assign(state, getDefaultState());
        },
        supportTickets(state, val) {
            state.supportTickets = val;
        },
        supportTicket(state, val) {
            state.supportTicket = val;
        },
        categories(state, val) {
            state.categories = val;
        },
    },
    actions: {
        async getSupportTickets(context) {
            const response = await get("/api/support");
            return context.commit("supportTickets", response);
        },
        async getSupportTicketsByID(context, id) {
            const response = await get("/api/support/" + id);
            return context.commit("supportTicket", response);
        },
        async getSupportCategories(context) {
            const response = await get("/api/support/categories");
            return context.commit("categories", response);
        },
        async postSupportTicket(context, data) {
            const response = await post("/api/support", data);
            await context.dispatch("getSupportTickets");
            return response;
        },
        async deleteSupportTicket(context, id) {
            const response = await del("/api/support/" + id);
            store.commit("notifications/raw", response);
            await context.dispatch("getSupportTickets");
            return response;
        },
        async putSupportTicket(context, data) {
            const response = await put("/api/support/" + data.id, data);
            await context.dispatch("getSupportTickets");
            store.commit("notifications/raw", response);
            return response;
        },
    },
};
