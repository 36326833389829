import { del, get, post } from "../assets/functions";
import store from "./index";

const getDefaultState = () => {
  return {
    options: {},
    invoice: {},
    report: [],
    saleReport: {}
  };
};

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    resetState(state) {
      Object.assign(state, getDefaultState());
    },
    invoice(state, val) {
      state.invoice = val;
    },
    options(state, val) {
      state.options = val;
    },
    report(state, val) {
      state.report = val;
    },
    saleReport(state, val) {
      state.saleReport = val;
    },
  },
  actions: {
    async getInvoiceByID(context, data) {
      const response = await get("/api/invoice/" + data);
      context.commit("invoice", response);
    },
    async getPaymentOptions(context, data) {
      const response = await get("/api/payment/options");
      context.commit("options", response);
    },
    async wiretransfer(context, data) {
      return await get("/api/payment/wiretransfer/" + data);
    },
    async stripe(context, data) {
      return await get("/api/payment/stripe/" + data);
    },
    async postStripe(context, data) {
      return await post("/api/payment/stripe", data);
    },
    async getSalesReport(context, data) {
      let response = await get(`/api/reseller/report/${data.year}/${data.month}`);
      context.commit("report", response);
      return response;
    },
  },
};
