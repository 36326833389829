<template>
  <div :style="styling">
    <div class="input_text_field">
      <input
        ref="field"
        @focus="onFocus()"
        @blur="onBlur()"
        @input="
          update();
          passwordStrength();
        "
        :style="inputStyling"
        :type="passwordType"
        autocomplete="off"
        data-lpignore="true"
        :disabled="disabled"
        :value="value"
        :placeholder="placeholder"
        onkeyup="this.setAttribute('value', this.value);"
      />
      <label>{{ label }}</label>
      <div
        v-if="password == ''"
        class="box center"
        :style="boxStyling"
        @click="showPassword = !showPassword"
      >
        <s-icon
          :title="tip && $t('atoms.tip.show_password')"
          v-show="!showPassword"
          width="20"
          color="grey"
          >eye-outline</s-icon
        >
        <s-icon
          :title="tip && $t('atoms.tip.hide_password')"
          v-show="showPassword"
          width="20"
          color="grey"
          >eye-off-outline</s-icon
        >
      </div>
    </div>
    <div class="line" ref="line" :class="{ red: error }"></div>
    <span v-show="error" class="errorMessage">{{ error }}</span>
    <div v-if="bar === ''" class="d-flex strength_container">
      <div v-show="strength > 0" class="strength red"></div>
      <div v-show="strength > 1" class="strength orange"></div>
      <div v-show="strength > 2" class="strength green"></div>
      <div v-show="strength > 3" class="strength blue"></div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  props: [
    "error",
    "label",
    "value",
    "type",
    "details",
    "block",
    "placeholder",
    "height",
    "disabled",
    "textCenter",
    "bar",
    "password",
    "noedit",
    "autofocus",
    "width",
  ],
  data() {
    return {
      strength: 0,
      newPassword: "",
      showPassword: false,
    };
  },
  methods: {
    onFocus() {
      if (!this.$refs.field) return;
      this.$refs.field.focus();
      this.$refs.line.style.width = "100%";
       this.$emit("onFocus");
    },
    onBlur() {
      if (!this.error) this.$refs.line.style.width = "0";
      this.$emit("blur");
    },
    update() {
      this.$emit("input", this.$refs.field.value);
    },
    passwordStrength() {
      this.strength = 0;
      if (this.bar == undefined) return;
      if (!this.bar === "") return;
      if (this.value.length === 0) return (this.strength = 0);
      if (!this.value.search(".*[a-z]")) this.strength = ++this.strength;
      if (!this.value.search(".*[A-Z]")) this.strength = ++this.strength;
      if (!this.value.search(".*[0-9]")) this.strength = ++this.strength;
      if (!this.value.search(".*[!@#$&*]")) this.strength = ++this.strength;
      if (this.value.length > 16) this.strength = ++this.strength;
      if (this.value.length < 8) this.strength = 1;
    },
  },
  computed: {
    ...mapState('user', ['tip']),
    styling: function () {
      let ob = {};
      if (this.block == "" || this.block == "true") ob.width = "100%";
      if (this.details == "" || this.details == "true") ob.minHeight = "55px";
      if (this.label) if (this.label.length > 0) ob.paddingTop = "20px";
      if (this.noedit == "") ob.pointerEvents = "none";

      return ob;
    },
    boxStyling: function () {
      let ob = {};
      if (this.height) ob.height = this.height + "px";
      if (this.height) ob.minWidth = this.height + "px";
      if (this.height) ob.maxWidth = this.height + "px";
      if (this.noedit == "") ob.display = "none";
      return ob;
    },
    inputStyling: function () {
      let ob = {};
      if (this.textCenter == "" || this.textCenter == "true")
        ob.textAlign = "center";
      if (this.height) ob.height = this.height + "px";
      if (this.width) ob.width = this.width + "px";
      if (this.noedit == "") ob.backgroundColor = "#00000000";
      return ob;
    },
    passwordType() {
      let showPassword = this.showPassword ? "text" : "password";
      return this.password != "" ? this.type : showPassword;
    },
  },
  watch: {
    error(val) {
      this.$refs.line.style.width = "100%";
    },
    value(val) {
      this.passwordStrength();
      if (this.value) this.$refs.field.setAttribute("value", this.value);
    },
  },
  mounted() {
    if (this.value) this.$refs.field.setAttribute("value", this.value);

    setTimeout(() => {
      if (this.autofocus == "") this.onFocus();
    }, 0);
  },
};
</script>



<style scoped>
.input_text_field {
  position: relative;
  display: flex;
  align-items: center;
}

label {
  font-size: 13px;
  position: absolute;
  transition: all ease 0.2s;
  left: 10px;
  pointer-events: none;
  margin: 0;
  color: rgba(255, 255, 255, 0.8);
}

.icon {
  position: absolute;
  right: 10px;
}

.line {
  width: 0;
  height: 1px;
  background-color: #409875;
  transition: all ease 0.2s;
}

.box {
  box-sizing: border-box;
  width: 40px;
  height: 40px;
  background-color: #151b23;
  margin-left: 2px;
}

.red {
  background-color: #df5151;
}

.errorMessage {
  font-size: 12px;
  color: #df5151;
  margin-left: 8px;
}

input {
  height: 40px;
  background-color: rgba(0, 0, 0, 0.35);
  font-size: 14px;
  color: rgba(255, 255, 255, 0.75);
  padding: 8px;
  width: 100%;
  box-sizing: border-box;
  font-family: Roboto;
}

input:hover {
  background-color: rgba(0, 0, 0, 0.45);
}

input:focus + label {
  transform: translateY(-28px);
  left: 8px;
  font-size: 10px;
}

input[value]:not([value=""]) + label {
  transform: translateY(-28px);
  left: 8px;
  font-size: 10px;
}

input:focus {
  background-color: #151b23;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.95);
  outline: none;
}

input:focus::placeholder {
  color: rgba(255, 255, 255, 0.55);
}

input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

input:disabled {
  background-color: rgba(0, 0, 0, 0.35);
  /* background-color: rgba(0, 0, 0, 0.0); */
  /* background-color: rgba(85, 79, 79, 0.35); */
}

.strength_container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 4px;
  min-height: 2px;
  margin-top: 5px;
}

.strength {
  width: 100%;
  height: 2px;
  grid-column-gap: 5px;
}

.red {
  background-color: #df5151;
}

.orange {
  background-color: #df9851;
}

.green {
  background-color: #55af6d;
}

.blue {
  background-color: #517cdf;
}
</style>
