<template>
  <img :src="iconName" :style="styling" :class="color + '_svg'" alt="" />
</template>

<script>
export default {
  props: ["width", "height", "color"],
  computed: {
    styling: function () {
      return {    
        Minheight: this.height + "px",
        Minwidth: this.width + "px",
        height: this.height + "px",
        width: this.width + "px",
      };
    },
    iconName: function () {
      return "/img/icons/" + this.$slots.default[0].text + ".svg";
    },
  },
};
</script>

<style scoped>

.white_svg {
  filter: invert(100%) sepia(98%) saturate(8%) hue-rotate(155deg)
    brightness(102%) contrast(106%);
}

.green_svg {
  filter: invert(53%) sepia(42%) saturate(512%) hue-rotate(104deg)
    brightness(88%) contrast(85%);
}

.red_svg {
  filter: invert(34%) sepia(76%) saturate(1102%) hue-rotate(326deg)
    brightness(102%) contrast(81%);
}

.grey_svg {
  filter: invert(76%) sepia(10%) saturate(156%) hue-rotate(165deg)
    brightness(89%) contrast(91%);
}

.blue_svg {
  filter: invert(71%) sepia(18%) saturate(999%) hue-rotate(164deg)
    brightness(95%) contrast(88%);
}

.dark_svg {
  filter: invert(15%) sepia(31%) saturate(753%) hue-rotate(178deg)
    brightness(94%) contrast(85%);
}
</style>
