<template>
  <section class="relative">
    <div class="d-flex w100" ref="button">
      <div class="w100" @click="toggle">
        <input
          ref="text"
          @keydown.prevent.enter="toggle"
          @keydown.prevent.esc="close"
          @keydown.prevent.down="firstOnList()"
          data-lpignore="true"
          type="input"
          :disabled="searchable !== ''"
          :style="inputStyling"
          autocomplete="do-not-autofill"
          v-model="selected"
          class="w100"
          :class="{ noedit: noedit, hideCaret: !show }"
        />
        <div class="line" ref="line"></div>
      </div>
      <button
        class="box center"
        type="button"
        v-if="!noedit"
        @click="toggle"
        tabindex="-1"
        :style="boxStyling"
      >
        <s-icon width="20" height="20" color="grey">chevron-down</s-icon>
      </button>
    </div>
    <transition name="select">
      <section
        v-hotkey="{ esc: close }"
        v-if="show"
        class="dropdown"
        :style="dropdown"
        ref="dropdown"
        v-outside="close"
      >
        <div v-if="itemType.length === 0" class="grow center pa-20 col">
          <span>No results</span>
          <span v-if="selected" class="font-12 opacity-75"
            >Invite "{{ selected }}" to the meeting</span
          >
        </div>
        <button
          ref="button"
          class="element"
          type="button"
          :style="element"
          @keydown.prevent.shift.tab="up($event)"
          @keydown.prevent.tab.exact="down($event)"
          @keydown.prevent.down="down($event)"
          @keydown.prevent.up="up($event)"
          @click="clickHandle(item)"
          :class="{ selected: item.selected, 'py-25': attendees == '' }"
          v-for="(item, index) in itemType"
          :key="index"
        >
          <div class="d-flex align-center gap-10">
            <div
              v-if="item.color"
              class="room_box mr-10"
              :style="{ backgroundColor: 'var(--sm-color-' + item.color + ')' }"
            ></div>

            <div v-if="attendees !== ''" class="">{{ item.display }}</div>

            <div
              v-if="attendees == ''"
              class="font-14 blue--text align-center item1 gap-10 ml-10"
              :class="{ item8: !$screen.sm }"
            >
              <s-icon v-if="!item.group" color="blue">account</s-icon>
              <s-icon v-else :title="'Group'" color="blue"
                >account-group</s-icon
              >
              <div v-if="!item.group" class="nowrap overflow-hidden col">
                <span class="overflow-hidden row justify-start"
                  >{{ item.display || item.value }}
                </span>
                <div v-if="item.display !== item.value" class="row">
                  <span
                    class="font-12 opacity-75 grey--text nowrap overflow-hidden"
                  >
                    {{ item.value }}
                  </span>
                </div>
              </div>
              <div v-else class="col overflow-hidden nowrap align-start">
                {{ item.display }}
                <span
                  class="font-12 opacity-75 grey--text nowrap overflow-hidden"
                >
                  {{ item.attendees }}
                </span>
              </div>
            </div>
          </div>
          <s-icon
            :key="key"
            v-show="item.selected"
            class="nopointer mr-10"
            height="16"
            color="white"
            >check</s-icon
          >
        </button>
      </section>
    </transition>
  </section>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      show: false,
      selected: "",
      placeholder: "",
      key: 0,
      multiSelect: [],
    };
  },
  props: [
    "height",
    "value",
    "noedit",
    "items",
    "multi",
    "searchable",
    "max",
    "attendees",
    "customPlaceholder",
    "noSelect"
  ],
  methods: {
    toggle() {
      this.show ? this.close() : this.open();
    },
    open() {
      this.$refs.text.placeholder = this.placeholder;
      this.$emit("focused");
      if (this.noedit) return;
      this.show = true;
      this.$refs.text.focus();
      this.$refs.line.style.width = "100%";
      if (this.searchable === "") this.selected = "";
    },
    findNextTabStop(el) {
      var universe = document.querySelectorAll(
        "input, button, select, textarea, a[href]"
      );
      var list = Array.prototype.filter.call(universe, function (item) {
        return item.tabIndex >= "0";
      });
      var index = list.indexOf(el);
      return list[index + 1] || list[0];
    },
    selectFirstEl() {
      let el = this.findNextTabStop(this.$refs.text);
      el.focus();
      el.click();
      this.show = false;
    },
    close() {
      let exists = this.items.find(
        (el) => el.value == this.selected || el.display == this.selected
      );
      // if (this.selected && exists) this.clickHandle(exists);
      this.$refs.text.placeholder = this.placeholder;
      this.$emit("focused");
      this.email = "";
      if (this.noedit) return;
      this.show = false;
      this.$refs.line.style.width = "0%";
      this.$refs.text.focus();
    },
    clickHandle(e) {
      let modify = e.selected ? -1 : 1;
      if (this.multiSelect.length + modify > this.max) {
        return this.$emit("add");
      }
      if (!this.multiple) this.items.forEach(this.unselect);
      if (!e.selected) {
        e.selected = true;
        this.multiSelect.push(e);

        this.key += 1;
      } else {
        this.key += 1;

        if (this.multiple) {
          e.selected = false;
          let index = this.multiSelect.findIndex((el) => el.value == e.value);
          this.multiSelect.splice(index, 1);
        }
      }

      this.$emit("select", {
        value: e.value,
        display: e.display,
        selected: e.selected,
      });

      this.$refs.text.placeholder = e.value;
      if (e.group) this.$refs.text.placeholder = e.display;
      if (this.multiple) this.$emit("multiSelect", this.multiSelect);
      if (this.searchable !== "") this.selected = e.display;

      this.$emit("input", e);
      if (this.multiple) return;
      this.$emit("blur");
      this.close();
    },
    unselect(val) {
      return (val.selected = false);
    },
    filter(val) {
      return val.selected == true;
    },
    updateSelected() {
      if (this.multiple) return;
      this.selected = this.items.filter(this.filter)[0];
      if (!this.selected) return;
      this.selected = this.selected.display;
    },
    down(el) {
      el = el.target;
      if (el.nextSibling) return el.nextSibling.focus();
      // if (!el.parentElement.firstChild) return;
      // el.parentElement.firstChild.focus();
    },
    up(el) {
      el = el.target;
      if (el.previousSibling) return el.previousSibling.focus();
      // el.parentElement.lastChild.focus();
    },
    focusSelectNode() {
      if (this.searchable === "") return;
      let selected = this.items.findIndex(this.filter);
      if (!selected) return;
      this.$refs.dropdown?.childNodes[selected]?.focus();
    },
    firstOnList(el) {
      var universe = document.querySelectorAll(".element");
      universe[0].focus();
    },
  },
  computed: {
    inputStyling: function () {
      let ob = {};
      if (this.noedit) ob.backgroundColor = "#00000000";
      if (this.height) ob.height = this.height + "px";
      return ob;
    },
    element: function () {
      let ob = {};
      if (this.height) ob.height = this.height + "px";
      return ob;
    },
    boxStyling: function () {
      let ob = {};
      if (this.height) ob.height = this.height + "px";
      if (this.height) ob.minWidth = this.height + "px";
      return ob;
    },
    dropdown: function () {
      let ob = {};
      if (this.height) ob.top = 1 + this.height + "px";
      return ob;
    },
    multiple: function () {
      let con1 = this.multi == true;
      let con2 = this.multi !== "";
      let con3 = typeof this.multi == "undefined";
      return con1 || con2 || con3 ? false : true;
    },
    filtered: function () {
      let con = this.selected.toLowerCase();
      return this.items.filter((val) => {
        if (!val?.display) val.display = "";
        if (!val?.value) val.value = "";
        return (
          val.display.toLowerCase().search(con) !== -1 ||
          String(val.value).toLowerCase().search(con) !== -1
        );
      });
    },
    itemType: function () {
      return this.searchable === "" ? this.filtered : this.items;
    },
    ...mapState("user", ["lang"]),
  },
  watch: {
    async show() {
      if (!this.show) return;
      await this.$wait();
      this.focusSelectNode();
    },
    lang() {
      this.updateSelected();
    },
    items() {
      if (!this.items) return;
      this.updateSelected();
      for (let x of this.items) {
        if (x.selected) {
          this.multiSelect.push(x);
        }
      }
    },
    selected() {
      if (this.searchable === "") this.show = true;
      this.$emit("typed", this.selected);
    },
  },
  mounted() {
    this.placeholder = this.customPlaceholder || "Choose";
    this.$refs.text.placeholder = this.placeholder;
    this.popupItem = this.$refs.button;
    if (this.multiple) {
      for (let x of this.items) {
        if (x.selected) {
          this.multiSelect.push(x);
        }
      }
    }
    if (!this.items || this.multiple) return;
    setTimeout(() => {
      let index = this.items.findIndex((el) => el?.selected == true);
      if (index == -1) index = 0;
      if (this.items.length == 0) return;
      if (!this.noSelect) this.items[index].selected = true;
      this.updateSelected();
      this.$emit("input", this.items[index]);
    }, 0);
  },
};
</script>

<style scoped lang='less'>
.dropdown {
  position: absolute;
  background-color: #151b23;
  z-index: 1;
  font-size: 14px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.35);
  min-width: 100%;
  max-height: 250px;
  overflow: overlay;
  top: 31px;
  max-width: -webkit-fill-available;
}

input {
  height: 30px;
  background-color: rgba(0, 0, 0, 0.35);
  font-size: 14px;
  color: rgba(255, 255, 255, 0.75);
  padding: 8px;
  width: 100%;
  box-sizing: border-box;
  user-select: none;
  cursor: pointer;
  text-align: start;
}

input:hover {
  background-color: rgba(0, 0, 0, 0.45);
}

.element {
  height: 30px;
  width: 133px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 5px;
  color: rgb(160, 160, 160);
  width: 100%;
  box-sizing: border-box;
  transition: all 0.2s ease;
  white-space: nowrap;
  overflow: hidden;
}

.element:hover {
  background-color: #00000054;
  color: rgb(255, 255, 255);
}

.item:focus {
  background-color: #000000a3;
  color: rgb(255, 255, 255);
}

.box {
  box-sizing: border-box;
  width: 30px;
  height: 30px;
  background-color: #151b23;
  margin-left: 2px;
}

.line {
  width: 0;
  height: 1px;
  background-color: #409875;
  transition: all ease 0.2s;
}

input:focus {
  background-color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  color: rgba(255, 255, 255, 0.95);
  outline: none;
}

input:focus-visible {
  background-color: rgba(0, 0, 0, 0.45);
}

button:focus-visible {
  background-color: rgba(0, 0, 0, 0.45);
}

input::selection {
  background: none;
}

.noedit {
  cursor: default;
}

.hideCaret {
  caret-color: transparent;
}

.noedit:focus {
  color: rgba(255, 255, 255, 0.75);
}

.room_box {
  height: 10px;
  width: 10px;
}

::placeholder {
  color: rgba(255, 255, 255, 0.75);
}

button:active {
  background-color: red;
}

@media only screen and (max-width: 576px) {
  .dropdown {
    left: -10px;
    top: 41px;
    width: 99vw;
  }
}
</style>