import { del, get, post } from "../assets/functions";
import store from "./index";

const getDefaultState = () => {
  return {
    licenses: [],
    license: {},
    active: 1,
    type: ''
  };
};

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    resetState(state) {
      Object.assign(state, getDefaultState());
    },
    licenses(state, val) {
      state.licenses = val;
    },
    license(state, val) {
      state.license = val;
    },
    active(state, val) {
      state.active = val;
    },
    type(state, val) {
      state.type = val;
    },
  },
  actions: {
    async getLicenses(context) {
      const response = await get("/api/license");
      context.commit("licenses", response);
    },
    async getLicensesByID(context, data) {
      const response = await get("/api/license/" + data);
      context.commit("license", response);
    },
    async postLicense(context, data) {
      const response = await post("/api/license", data);
      await context.dispatch("getLicenses");
      return response;
    },
    async deleteLicense(context, data) {
      const response = await del("/api/license/" + data);
      store.commit("notifications/raw", response);
      await context.dispatch("getLicenses");
      return response;
    },
  },
};
