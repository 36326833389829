import { del, get, post } from "../assets/functions";
import store from "./index";

export default {
  namespaced: true,
  state: {
    customers: [],
    customer: {}
  },
  mutations: {
    customers(state, response) {
      state.customers = response;
    },
    customer(state, response) {
      state.customer = response;
    },
  },
  actions: {
    async getCustomers(context) {
      const response = await get("/api/reseller/customers");
      context.commit("customers", response);
      return response;
    },

    async postCustomers(context, data) {
      const response = await post("/api/reseller/customers", data);
      store.commit("notifications/raw", response);
      await context.dispatch("getAttendeesInformation");
      return response;
    },
  },
};
