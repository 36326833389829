<template>
  <Modal>
    <form class="col gap-50">
      <section class="header row space-between align-center">
        <span class="font-18">Delete Account</span>
        <button @click="close" class="center">
          <s-icon color="grey">close</s-icon>
        </button>
      </section>
      <section class="body">
        <span> Are you sure you want to delete </span>
        <span> {{ user.email }}</span>
      </section>
      <section class="footer row gap-20 justify-end">
        <s-btn height="36" type='button' @click.native="close">
          {{ $t("modal.cancel") }}
        </s-btn>
        <s-btn height="36" class="red" type='button' @click.native="post">
                {{ $t("modal.delete") }}
        </s-btn>
      </section>
    </form>
  </Modal>
</template>

<script>
import { mapState } from "vuex";
export default {
  methods: {
    post() {
      this.$store.dispatch("accounts/deleteUser", this.user.id);
      this.close();
    },
    close() {
      this.$store.commit("modals/deleteAccountConfirmation", false);
    },
  },
  computed: {
    ...mapState("accounts", ["user"]),
  },
};
</script>