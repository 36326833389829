import router from "../router/index";
import store from "../store/index";

export const auth = function(response) {
  if (!response) return true;
  if (router.currentRoute.name === "Login") return true;
  if (response.errorCode !== "authentication_error") return true;
  if (!store.state.user.email) return true;
  store.commit("user/clear");
  let partners = router.currentRoute.path.includes('partners')
  let admin = router.currentRoute.path.includes('admin')
  if (admin) router.push("/admin/login");
  else if (partners) router.push("/partners/login");
  else router.push("/login");
  return false;
};

const header = { "Content-type": "application/json; charset=UTF-8" };

export const get = async function(url) {
  url = process.env.VUE_APP_URL + url;
  let response = await fetch(url, {
    method: "GET",
    credentials: "include",
    headers: header,
  });
  response = await response.json();
  auth(response);
  if (response.errorCode == 'authentication_error') return;
  return response;
};

export const post = async function(url, data) {
  url = process.env.VUE_APP_URL + url;
  let response = await fetch(url, {
    method: "POST",
    body: JSON.stringify(data),
    credentials: "include",
    headers: header,
  });
  response = await response.json();
  auth(response);
  return response;
};

export const put = async function(url, data) {
  url = process.env.VUE_APP_URL + url;
  let response = await fetch(url, {
    method: "PUT",
    body: JSON.stringify(data),
    credentials: "include",
    headers: header,
  });
  response = await response.json();
  auth(response);
  return response;
};

export const del = async function(url) {
  url = process.env.VUE_APP_URL + url;
  let response = await fetch(url, {
    method: "DELETE",
    credentials: "include",
    headers: header,
  });
  response = await response.json();
  auth(response);
  return response;
};

export const searchList = function (list, term) {
  if (!term) return list;
  if (!Array.isArray(list)) return [];
  return list.filter((el) => Object.values(el).filter(util).length > 0);
  
  function util(item) {
      let result = JSON.stringify(item || "");
      result = result.toLowerCase().replaceAll('"', "");
      return result.search(term.toLowerCase()) >= 0;
  }
}
