import { del, get, post, put } from "../assets/functions";
import store from "./index";

export default {
    namespaced: true,
    state: {
        users: [],
        user: {}
    },
    mutations: {
        users(state, response) {
            state.users = response;
        },
        user(state, response) {
            state.user = response;
        },
    },
    actions: {
        async getUsers(context) {
            const response = await get("/api/reseller/users");
            context.commit("users", response);
            return response;
        },
        async getUser(context, data) {
            const response = await get("/api/reseller/users/" + data);
            context.commit("user", response);
            return response;
        },
        async postUser(context, data) {
            const response = await post("/api/reseller/users", data);
            store.commit("notifications/raw", response);
            await context.dispatch("getUsers");
            return response;
        },
        async deleteUser(context, data) {
            const response = await del("/api/reseller/users/" + data);
            store.commit("notifications/raw", response);
            await context.dispatch("getUsers");
            return response;
        },
        async putUser(context, data) {
            let id = context.state.user.id
            const response = await put("/api/reseller/users/" + id, data);
            store.commit("notifications/raw", response);
            await context.dispatch("getUsers");
            return response;
        },
    },
};
