import Vue from 'vue'
import VueI18n from 'vue-i18n'
import store from "../store/index";

import 'dayjs/locale/de' 
import 'dayjs/locale/fr' 
import 'dayjs/locale/sk' 
import 'dayjs/locale/pl' 
import 'dayjs/locale/el' 
import 'dayjs/locale/es' 
import 'dayjs/locale/pt' 
import 'dayjs/locale/hi' 
import 'dayjs/locale/zh-cn' 
import 'dayjs/locale/zh-tw' 
import 'dayjs/locale/nl' 

Vue.use(VueI18n)

function loadLocaleMessages () {
  const locales = require.context('../locals', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1].toLowerCase();
      messages[locale] = locales(key)
    }
  })
  return messages
}

export default new VueI18n({
  locale: 'en',
  fallbackLocale:  'en',
  messages: loadLocaleMessages()
})
