import { get, post } from "../assets/functions";
export default {
  namespaced: true,
  state: {
    invoice: {},
    invoices: [],
  },
  mutations: {
    invoice(state, response) {
      state.invoice = response;
    },
    invoices(state, response) {
      state.invoices = response;
    },
  },
  actions: {
    async getInvoiceByID(context, data) {
      const response = await get("/api/invoice/" + data);
      context.commit("invoice", response);
    },
    async getInvoices(context, data) {
      const response = await get("/api/invoice");
      context.commit("invoices", response);
    },
  },
};
