import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Dashboard",
    component: () => import("../views/Dashboard.vue"),
    redirect: '/licenses',
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "/profile",
        name: "profile",
        component: () => import("@/components/Profile/Profile.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/support",
        name: "support",
        component: () => import("@/components/Customers/Support.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/support/view/:id",
        name: "supportView",
        component: () => import("@/components/Customers/ViewSupport.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/billing",
        name: "billing",
        component: () => import("@/components/Billing/Billing.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/licenses",
        name: "licenses",
        component: () => import("@/components/Licences/Licences.vue"),
        meta: {
          requiresAuth: true,
          dashboard: true,
        },
      },
      {
        path: "/invoices",
        name: "invoices",
        component: () => import("@/components/Invoices/Invoices.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/invoices/view/",
        name: "invoiceView",
        component: () => import("@/components/Invoices/View.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/licenses/system",
        name: "add",
        component: () => import("@/components/Licences/WizardLicense.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/licenses/product",
        name: "addProduct",
        component: () => import("@/components/Licences/Add_License.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/licenses/confirmation",
        name: "confirmation",
        component: () => import("@/components/Licences/Confirmation.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/licenses/view",
        name: "view",
        component: () => import("@/components/Licences/View.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/licenses/invoice/:id",
        name: "viewInvoice",
        component: () => import("@/components/Licences/Billing.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/licenses/invoice/:id/wiretransfer",
        name: "wire",
        component: () => import("@/components/Licences/Wire.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/licenses/invoice/:id/stripe",
        name: "stripe",
        component: () => import("@/components/Licences/Stripe.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/licenses/success",
        name: "success",
        component: () => import("@/components/Licences/SuccessComp.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/licenses/declined",
        name: "declined",
        component: () => import("@/components/Licences/DeclinedComp.vue"),
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: "/partners",
    name: "Partners",
    component: () => import("../views/Dashboard.vue"),
    redirect: '/partners/dashboard',
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "/partners/dashboard",
        name: "ResellerDashboard",
        component: () => import("@/components/Reseller/Dashboard.vue"),
        meta: {
          requiresAuth: true,
          dashboard: true,
        },
      },
      {
        path: "/partners/customers",
        name: "ResellerCustomers",
        component: () => import("@/components/Reseller/Customers.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/partners/customers/view",
        name: "viewCustomers",
        component: () => import("@/components/Reseller/View.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/partners/products",
        name: "ResellerProducts",
        component: () => import("@/components/Reseller/Products.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/partners/profile",
        name: "ResellerProfile",
        component: () => import("@/components/Profile/ResellerProfile.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/partners/sales",
        name: "ResellerSales",
        component: () => import("@/components/Reseller/SalesReport.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/partners/sales/view",
        name: "ResellerViewSales",
        component: () => import("@/components/Reseller/ViewSalesReport.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/partners/accounts",
        name: "Accounts",
        component: () => import("@/components/Reseller/Accounts.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/partners/accounts/view",
        name: "AccountsView",
        component: () => import("@/components/Reseller/ViewAccount.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/partners/accounts/add",
        name: "AccountsAdd",
        component: () => import("@/components/Reseller/AddAccount.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/partners/accounts/edit",
        name: "AccountsEdit",
        component: () => import("@/components/Reseller/EditAccount.vue"),
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: "/admin",
    name: "admin",
    component: () => import("../views/Dashboard.vue"),
    redirect: '/admin/dashboard',
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "/admin/dashboard",
        name: "adminDashboard",
        component: () => import("@/components/Admin/Dashboard/Dashboard.vue"),
        meta: {
          requiresAuth: true,
          dashboard: true,
        },
      },
      {
        path: "/admin/partner",
        name: "adminpartners",
        component: () => import("@/components/Admin/Partners/Partners.vue"),
        meta: {
          requiresAuth: true,
          dashboard: true,
        },
      },
      {
        path: "/admin/partner/customer/:id",
        name: "adminpartnersCustomer",
        component: () => import("@/components/Admin/Partners/ViewCustomer.vue"),
        meta: {
          requiresAuth: true,
          dashboard: true,
        },
      },
      {
        path: "/admin/partner/user/:id",
        name: "adminpartnersUser",
        component: () => import("@/components/Admin/Partners/ViewUser.vue"),
        meta: {
          requiresAuth: true,
          dashboard: true,
        },
      },
      {
        path: "/admin/partner/id/:id",
        name: "adminPartnersByID",
        component: () => import("@/components/Admin/Partners/View.vue"),
        meta: {
          requiresAuth: true,
          dashboard: true,
        },
      },
      {
        path: "/admin/customers/id/:id",
        name: "adminCustomersByID",
        component: () => import("@/components/Admin/Customers/View.vue"),
        meta: {
          requiresAuth: true,
          dashboard: true,
        },
      },
      {
        path: "/admin/customers",
        name: "admincustomers",
        component: () => import("@/components/Admin/Customers/Customers.vue"),
        meta: {
          requiresAuth: true,
          dashboard: true,
        },
      },
    ],
  },
  {
    path: "/login",
    name: "CustomerLogin",
    component: () => import("../views/CustomerLogin.vue"),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/partners/login",
    name: "ResellerLogin",
    component: () => import("../views/ResellerLogin.vue"),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/admin/login",
    name: "AdminLogin",
    component: () => import("../views/AdminLogin.vue"),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/registration",
    name: "CustomerRegistration",
    component: () => import("../views/CustomerRegistration.vue"),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/registration/:hash",
    name: "CustomerRegistrationHash",
    component: () => import("../views/CustomerRegistration.vue"),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/partners/registration",
    name: "ResellerRegistration",
    component: () => import("../views/ResellerRegistration.vue"),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/lostpassword",
    name: "LostPassword",
    component: () => import("../views/LostPassword.vue"),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/partners/lostpassword",
    name: "ResellerLostPassword",
    component: () => import("../views/LostPassword.vue"),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/resetpassword/:hash",
    name: "ResetPassword",
    component: () => import("../views/ResetPassword.vue"),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/partners/resetpassword/:hash",
    name: "ResellerResetPassword",
    component: () => import("../views/ResetPassword.vue"),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/partners/downloads",
    name: "PartnersDownloads",
    component: () => import("../views/Download.vue"),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/downloads",
    name: "Downloads",
    component: () => import("../views/Download.vue"),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "*",
    component: () => import("../views/404.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach(async (to, from, next) => {

  let authRoute = from.meta.authRoute;
  let dashboard = to.meta.dashboard;
  let user = store.state.user.email;
  let partners = to.path.includes('partners')
  let admin = to.path.includes('admin')
  store.commit("user/routerHistory", to.name);
  let redirect = partners ? 'ResellerLogin' : 'CustomerLogin'
  redirect = admin ? 'AdminLogin' : redirect

  if (authRoute && dashboard) {
    let counter = 0;
    let intervalID = setInterval(() => {
      if (store.state.user.email) {
        clearInterval(intervalID);
        return next();
      }
      if (++counter > 1000) clearInterval(intervalID);
    }, 10);
    return;
  }

  if (authRoute) return next();
  if (!to.meta.requiresAuth) return next();
  if (!store.state.user.email) return next({ name: redirect });
  if (store.state.user.email) return next();

});

export default router;
