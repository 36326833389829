<template>
  <section class="modalContainer">
    <Notifications />
    <DeleteAccountConfirmation v-if="deleteAccountConfirmation" />
    <DeleteSupportConfirmation v-if="deleteSupportConfirmation" />
    <DeleteLicenseConfirmation v-if="deleteLicenseConfirmation" />
    <AppInfo v-if="appInfo" />
    <supportTicket v-if="supportTicket" />
  </section>
</template>

<script>
import { mapState } from "vuex";
import Notifications from "@/components/Modals/Notifications";
import DeleteAccountConfirmation from "@/components/Modals/DeleteAccountConfirmation";
import DeleteSupportConfirmation from "@/components/Modals/DeleteSupportConfirmation";
import DeleteLicenseConfirmation from "@/components/Modals/DeleteLicenseConfirmation";
import supportTicket from "@/components/Modals/AddSupportTicket";
import AppInfo from "@/components/Modals/AppInfo";
export default {
  components: {
    Notifications,
    DeleteAccountConfirmation,
    DeleteSupportConfirmation,
    DeleteLicenseConfirmation,
    AppInfo,
    supportTicket
  },
  computed: {
    ...mapState("modals", [
      "deleteAccountConfirmation",
      "deleteSupportConfirmation",
      "deleteLicenseConfirmation",
      "appInfo",
      "supportTicket"
    ]),
  },
};
</script>