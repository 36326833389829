<template>
  <button
    class="button center uppercase relative px-15 b-box"
    :disabled="disabled || loading"
    :style="styling"
    :type="type"
    ref="button"
  >
    <pulse-loader
      :loading="load"
      :color="'var(--green)'"
      size="10px"
    ></pulse-loader>
    <slot v-if="!load"></slot>
  </button>
</template>

<script>
export default {
  props: [
    "width",
    "height",
    "color",
    "opacity",
    "font",
    "block",
    "min",
    "disabled",
    "icon",
    "outlined",
    "type",
    "loading",
  ],
  computed: {
    load() {
      let con1 = this.loading == true;
      let con2 = this.loading !== "";
      let con3 = typeof this.loading !== "undefined";
      if (con1 && con2 && con3) return true;
      return false;
    },
    styling: function () {
      let ob = {
        height: this.height + "px",
        minWidth: this.width + "px",
        backgroundColor: this.color,
        opacity: this.opacity,
        fontSize: this.font + "px",
      };
      if (
        this.block == true &&
        this.block !== "" &&
        typeof this.block !== "undefined"
      ) {
        ob.width = "auto";
      }

      if (
        this.block == false &&
        this.block == "" &&
        typeof this.block !== "undefined"
      ) {
        ob.width = "100%";
      }
      if (this.outlined == "") ob.border = "1px solid black";
      if (this.outlined == "") ob.color = "black";
      if (this.outlined == "") ob.backgroundColor = "transparent";

      if (this.min == "" || this.min == "true") ob.minWidth = 0;
      return ob;
    },
  },
  methods: {
    onFocus() {
      this.$refs.button.focus();
    },
  },
};
</script>

<style scoped lang="less">
.iconLayout {
  display: grid;
  grid-template-columns: 25px 1fr 10px;
  padding-left: 10px;
}

.slot {
  margin: 20px;
}

.button {
  min-width: 95px;
  height: 30px;
  background-color: #444954;
  color: #ffffff;
  font-family: Roboto;
  font-size: 16px;
  outline: none;
  white-space: nowrap;
  flex-wrap: nowrap;
  overflow: hidden;
}

.button:focus {
  outline: 3px solid #425464;
}

.button:hover {
  background-color: #425464;
}

.button:disabled {
  min-width: 95px;
  height: 30px;
  background-color: #444954;
  color: #ffffff;
  font-family: Roboto;
  font-size: 16px;
  outline: none;
  cursor: not-allowed;
}

.button:disabled:hover {
  background-color: #425464;
  cursor: not-allowed;
}

.red {
  background-color: #9e3d3d;
}

.red:hover {
  background-color: #df5151;
}

.greenHover:hover {
  background-color: #56bc93;
}

.green {
  background-color: #409875;
}

.green:hover {
  background-color: #56bc93;
}

.green:focus {
  outline: 2px solid #56bc93;
}

.red:focus {
  outline: 2px solid #df5151;
}
</style>


